import { Component, ComponentFactoryResolver, ElementRef, OnInit, resolveForwardRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ConnectApiService } from '../connect.api.service';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-register-preapprove',
  templateUrl: './register-preapprove.component.html',
  styleUrls: ['./register-preapprove.component.scss']
})
export class RegisterPreapproveComponent implements OnInit {


  formGroup: FormGroup
  url: string = ''
  disabled = { cid: true };
  hidden_text_title_name = true;
  show_c3 = true;
  constructor(public api: ConnectApiService, private route: ActivatedRoute, private el: ElementRef) {
    this.url = this.route.snapshot.paramMap.get("code")
    this.formGroup = new FormGroup({
      user_ref: new FormControl('', Validators.required),
      name_title: new FormControl('นาย', Validators.required),
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      number_phone: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.minLength(10)]),
      user_email: new FormControl('', Validators.email),
      renew: new FormControl('0', Validators.required),
      cid: new FormControl('', [Validators.required, Validators.maxLength(13), Validators.minLength(13), Validators.pattern(/^-?(0|[1-9]\d*)?$/)]),
    })
    this.formGroup.controls['cid'].disable();
    this.formGroup.controls['name_title'].disable();
    this.formGroup.controls['first_name'].disable();
    this.formGroup.controls['user_email'].disable();
    this.formGroup.controls['number_phone'].disable();
    this.formGroup.controls['last_name'].disable();

  }
  json(val) {
    return JSON.stringify(val);
  }
  title_change(val) {
    console.log(val);
    if (val == '') {
      this.formGroup.controls['name_title'].patchValue('');
      this.hidden_text_title_name = false;
      setTimeout(() => {
        const formGroupInvalid = this.el.nativeElement.querySelectorAll('.name_title');
        (<HTMLInputElement>formGroupInvalid[0]).focus();
      }, 100);

    }


  }
  ngOnInit(): void {

    this.round(this.url);
    let date = new Date();
    let now = parseInt(date.getFullYear() + '' + (date.getMonth() + 1) + date.getDate());
    console.log(now);
    if (now > 2024815) {
      this.show_c3 = false;
    }

  }
  u_id = 0;
  disable_btn = true;
  change_user_ref(val) {
    console.log(this.url);
    if (this.url == '64a7d83d80681') {
      Swal.fire({
        icon: 'warning',
        title: 'ขออภัย',
        text: 'ปิดรับลงทะเบียน',
        // footer: '<a href="">Why do I have this issue?</a>'
      });
      return;


    }


    this.api.postData_nologin("check_user_ref", { user_ref: val, code: this.url }).then((res: any) => {
      if (res.flag) {
        if (res.data.status == '1') {
          Swal.fire({
            icon: 'warning',
            title: 'Oops...',
            text: 'ท่านเคยลงทะเบียนแล้ว! กรุณาตรวจสอบ email เพื่อเข้าระบบ',
            // footer: '<a href="">Why do I have this issue?</a>'
          })
          this.disable_btn = true;
        } else {
          this.disable_btn = false;
          this.formGroup.controls['user_ref'].disable();
          this.u_id = res.data.u_id;
          if (res.data.user_ref.length == 7) {
            this.show_c3 = false;
          }
          if (res.data.cid != '') {
            this.formGroup.controls['cid'].setValue(res.data.cid);
          } else {
            this.formGroup.controls['cid'].enable();
          }
          if (res.data.name_title != '') {
            this.formGroup.controls['name_title'].setValue(res.data.name_title);
            this.hidden_text_title_name = false;
          } else {
            this.formGroup.controls['name_title'].enable();
          }

          if (res.data.first_name != '') {
            this.formGroup.controls['first_name'].setValue(res.data.first_name);
          } else {
            this.formGroup.controls['first_name'].enable();
          }

          if (res.data.user_email != '') {
            this.formGroup.controls['user_email'].setValue(res.data.user_email);
          } else {
            this.formGroup.controls['user_email'].enable();
          }

          if (res.data.number_phone != '') {
            this.formGroup.controls['number_phone'].setValue(res.data.number_phone);
          } else {
            this.formGroup.controls['number_phone'].enable();
          }
          if (res.data.last_name != '') {
            this.formGroup.controls['last_name'].setValue(res.data.last_name);
          } else {
            this.formGroup.controls['last_name'].enable();
          }

        }
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: 'ไม่พบข้อมูล กรุณาตรวจสอบรหัสพนักงาน/ตัวแทน หรือ ติดต่อ Trainer ประจำหน่วย',
          // footer: '<a href="">Why do I have this issue?</a>'
        })
        this.disable_btn = true;
      }

    })

  }

  course: any = {}
  round(code) {
    this.api.getData_nologin("../Front_web/loadRound/" + code).then((res: any) => {
      if (res.page_status === 1) {
        this.course = res.round
      } else {
        location.href = 'https://e-learning.aimc.or.th'
      }
    })
  };

  save() {
    let data = {
      user_ref: this.formGroup.controls['user_ref'].value,
      name_title: this.formGroup.controls['name_title'].value,
      first_name: this.formGroup.controls['first_name'].value,
      last_name: this.formGroup.controls['last_name'].value,
      number_phone: this.formGroup.controls['number_phone'].value,
      user_email: this.formGroup.controls['user_email'].value,
      cid: this.formGroup.controls['cid'].value,
      renew: this.formGroup.controls['renew'].value,

      code: this.url,
      u_id: this.u_id
    }
    console.log(data);
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success ml-2',
        cancelButton: 'btn btn-dark ml-2'
      },
      buttonsStyling: false
    });
    let html = "รหัสพนักงาน : " + data.user_ref + "<br/>";
    html += "รหัสบัตรประชาชน  : " + data.cid + "<br/>";
    html += "ชื่อ  : " + data.name_title + " " + data.first_name + " " + data.last_name + "<br/>";
    html += "เบอร์โทรศัพท์  : " + data.number_phone + "<br/>";
    html += "E-mail  : " + data.user_email + "<br/>";
    Swal.fire({
      // icon: 'question',
      title: 'ยืนยันการบันทึก?',
      html: html,
      showDenyButton: false,
      showCancelButton: true,
      confirmButtonText: 'บันทึก',
      cancelButtonText: 'แก้ไข'
    }).then((result) => {
      if (result.isConfirmed) {
        this.api.postData_nologin('register_data_pre', data).then((res: any) => {

          if (res - 0 == 1) {
            swalWithBootstrapButtons.fire({
              title: 'บันทึกข้อมูลเรียบร้อยแล้ว',
              // text: "ยืนยันการลงทะเบียนของท่าน ท่านสามารถเข้าเรียนได้เลย",
              html: "<center>ท่านสามารถเข้าระบบ e-learning โดยการคัดลอกลิงค์ <a href='https://e-learning.aimc.or.th/'>https://e-learning.aimc.or.th/</a> เปิดผ่าน Chrome หรือ Safari<br>Username : เลขบัตรประชาชน<br>Password  : เลขบัตรประชาชน</center>",
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'ตกลง',
              //                                                cancelButtonText: 'ปิดออก',
              reverseButtons: true
            }).then(function (result) {
              window.location.reload();
              // window.location.href = "https://e-learning.aimc.or.th/";
            });
            // Swal.fire('บันทึกสำเร็จ!', '', 'success').then(() => {
            //   location.href = 'https://e-learning.aimc.or.th'
            // })
          } else if (res - 0 == 2) {
            Swal.fire('คุณเคยลงทะเบียนแล้ว', '', 'warning');
          } else if (res - 0 == 3) {
            Swal.fire('รอบนี้มีคนลงทะเบียเต็มแล้ว', '', 'warning');
          } else if (res - 0 == 4) {
            Swal.fire('คุณเคยลงทะเบียนหลักสูตรนี้ในรอบอื่นแล้ว', '', 'warning');
          } else {
            Swal.fire('ผิดผลาด!', '', 'error')
          }
        })
      }
    })
  }
}
