import { Component, ElementRef, HostListener, Input, OnInit, ViewChild, AfterContentInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbSlideEvent, NgbModal, ModalDismissReasons, NgbCarousel, NgbModalConfig, NgbSlideEventSource, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConnectApiService } from '../connect.api.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable, Subscription, fromEvent, animationFrameScheduler } from 'rxjs';
declare var Vimeo: any;
import { animate, style, transition, trigger } from '@angular/animations';
import Swal from 'sweetalert2'
import { isDefined } from '@angular/compiler/src/util';
declare var faceapi: any;

import * as $ from 'jquery';


@Component({
  selector: 'app-learning',
  templateUrl: './learning.component.html',
  styleUrls: ['./learning.component.scss'],
  animations: [
    trigger('slideDownUp', [
      transition(':enter', [style({ height: 0 }), animate(500)]),
      transition(':leave', [animate(500, style({ height: 0 }))]),
    ]),
  ],
})
export class LearningComponent implements OnInit {

  retryCount: number = 0;
  maxRetries: number = 3;
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public rt_id = '0';
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];
  @ViewChild('pdfviewer') pdfView: ElementRef;
  // latest snapshot
  public webcamImage: WebcamImage = null;
  @ViewChild('carousel_info') carousel_info: NgbCarousel;
  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  ls_id = '0';
  pdfSrc = "https://e-learning.aimc.or.th/api/Media/1621099885_60a0056d0801d.pdf";
  img_viewer = "";
  closeResult = '';
  courseid = '';
  topicid = '';
  active_tab = 2;
  data = {
    quizs: [],
    list: [],
    take_picture: [],

    detail: {
      is_exp: true,
      rt_id: 0,
      cer_url: '',
      kyc: { profile: '' },
      is_sliding: '0',
      tg_id: '',
      lecturer: { avata: '', name: '', rank: '', bio: '' },
      advice: '',
      topic: '',
      cer_id: '0',
      percen: 0,
      document: [],
      cg_id: '',
      ut_id: 0,
      survey: '0',
      sf_id: '0',
      group_survey: '0'

    }
  };
  title = '';
  video_playing = { video: { duration: 0 } };
  @ViewChild('topic') topic_ele: ElementRef<HTMLElement>;
  list_selected = { type: '', ls_id: '', ref_id: '', info: { info_data: [] }, exam: { title: '', count: 0, time: '', percent: '', ex_repeat: 0 }, video: { title: '' }, status: 0 };
  @Input() url: string = "https://player.vimeo.com/video/";

  urlSafe: SafeResourceUrl;
  urlCanva: SafeResourceUrl;
  public seconds = 0;
  public timeline = [];
  @ViewChild('iframe') input;
  @ViewChild('quiz_modal') quizmodalEle;
  @ViewChild('take_photo_modal') take_photomodalEle;
  @ViewChild('finish_video') finish_videomodalEle;
  @ViewChild("canvas") public canvas: ElementRef;
  @ViewChild("photo_viewer") public photo_viewer: ElementRef;
  public player = null;
  public overflowscallright = false;
  is_playing = false;
  quiz_finish = false;
  quiz_first = true;
  quiz_select = false;
  quiz_show_finish = false;

  test_show_finish = false;
  test_finish = false;
  test_first = true;
  test_select = false;
  @ViewChild('carousel', { static: true }) carousel: NgbCarousel;
  @ViewChild('carouselTest', { static: true }) carouselTest: NgbCarousel;
  @ViewChild('videoElement') videoElement: any;
  video: any;
  public WebcamImage: WebcamImage = null;
  pageVariable = 1;
  pdf_zoom = 0.8;
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription
  time_logout_run = 300;
  time_logout_fix = this.time_logout_run;
  constructor(private modalService: NgbModal,
    public router: Router,
    private elem: ElementRef,
    public ModalConfig: NgbModalConfig, public api: ConnectApiService,
    private route: ActivatedRoute, public sanitizer: DomSanitizer) {
    ModalConfig.backdrop = 'static';
    ModalConfig.keyboard = false;


  }
  // ngAfterViewInit() {
  //   this.checkForErrors();
  // }

  // refreshVideo() {

  //   const iframe = this.input?.nativeElement;
  //   if (iframe) {
  //     const src = iframe.src;
  //     iframe.src = '';
  //     setTimeout(() => {
  //       iframe.src = src;
  //     }, 500);
  //   } else {
  //     console.error('Iframe element not found');
  //   }
  // }
  refreshVideo() {
    localStorage.setItem('scrollPosition', window.scrollY.toString());
    window.location.reload(); // This will reload the entire page
  }
  sanitizeUrl = (url: string): SafeResourceUrl => {

    try {
      const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

      return safeUrl;
    } catch (error) {
      console.error('Error sanitizing URL:', error);
      this.refreshVideo();
      return null;
    }
  }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // console.log('keyboard');
    this.time_logout_run = this.time_logout_fix;
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    this.time_logout_run = this.time_logout_fix;
    // if (this.elem.nativeElement.contains(event.target)) {
    //   console.log("clicked inside");
    // } else {
    //   console.log("clicked outside");
    // }
  }

  update_play(next) {
    const videoUrl = this.url + next.video.video_id;
    this.urlSafe = this.sanitizeUrl(videoUrl);
    if (!this.urlSafe) {
      console.error('Failed to sanitize URL');
      return;
    }
    setTimeout(() => {
      this.player = new Vimeo.Player(this.input.nativeElement);
      if (!this.player) {
        console.error('Failed to create Vimeo Player');
        return;
      }
      if (next.status == 1) {
        this.player.setCurrentTime(next.playing.seconds);
        this.max_video_play = next.playing.seconds;
        this.seconds = next.playing.seconds;
      }

      next.video.metadata.forEach(element => {
        if (this.data.detail.is_sliding != '1') {
          if (this.not_take(element, next.ref_id) && this.not_quiz(element)) {
            this.player.addCuePoint(element.time, {
              customKey: element.type,
              data: element,
            }).then((id) => { }).catch((error) => { });
          }
        } else if (element.type == 'quiz') {
          this.player.addCuePoint(element.time, {
            customKey: element.type,
            data: element,
          }).then((id) => { }).catch((error) => { });
        }
      });

      this.player.on("cuepoint", (val: any) => {
        this.player.pause();
        if (val.data.customKey == 'quiz') {
          this.quizModal(val);
        } else if (val.data.customKey == 'take_photo') {
          this.take_photo(val);
        }
      });

      this.player.on('play', () => {
        this.time_logout_run = this.time_logout_fix;
        this.is_playing = true;
        try {
          this.activeM = 0;
          // console.log('play');
          this.update_data(0, true);
        } catch (error) {
          console.error('Error during play event:', error);
          this.refreshVideo();
        }
      });

      this.player.on('pause', () => {
        this.update_data(0, false);
        this.is_playing = false;
      });

      setInterval(() => {
        // console.log("update_data video");
        // console.log(this.is_playing);

        if (this.is_playing) {
          this.update_data(0, false);
        }
      }, 30000);

      this.player.on('ended', () => {
        console.log("ended");
      });

      this.player.on('timeupdate', (data) => {
        this.seconds = Math.round(data.seconds);
        if (this.seconds == this.video_playing.video.duration) {
          this.update_data(1, true);
        }
        next.video.metadata.forEach(element => {
          if (element.type == 'take_photo' && this.not_take(element, next.ref_id) && this.seconds > element.time && !this.is_take_photo) {
            this.is_take_photo = true;
            this.player.pause();
            let para = { data: { customKey: 'take_photo', data: element }, time: element.time, id: 0 };
            this.take_photo(para);
          }
        });
        if (this.data.detail.is_sliding == '0' && this.user.type == '0' && (next.status - 0 == 1 || next.status - 0 == 0)) {
          if (data.seconds > this.max_video_play && data.seconds - 1 < this.max_video_play) {
            this.max_video_play = data.seconds;
          } else {
            if (data.seconds > this.max_video_play) {
              this.player.setCurrentTime(this.max_video_play);
            }
          }
        }
      });
    }, 1000);
  }

  update_data(success, load) {
    if (this.list_selected.type == 'video') {
      this.player.getCurrentTime().then((seconds) => {
        this.api.postData("update_data", {
          rt_id: this.data.detail.rt_id,
          type: this.list_selected.type,
          ref_id: this.list_selected.ref_id,
          seconds: seconds,
          success: success,
          cg_id: this.data.detail.cg_id
        }).then((res: any) => {
          if (load) {
            this.load(success == 1);
          } else if (success) {
            this.load(false);
          }
        });
      });
    } else if (['pdf', 'info', 'test', 'canva', 'quiz'].includes(this.list_selected.type)) {
      this.api.postData("update_data", {
        rt_id: this.data.detail.rt_id,
        type: this.list_selected.type,
        ref_id: this.list_selected.ref_id,
        seconds: this.seconds,
        success: success,
        cg_id: this.data.detail.cg_id
      }).then((res: any) => {
        if (load) {
          this.load(success == 1);
        } else if (success) {
          this.load(false);
        }
      });
    }
  }

  play_data() {
    let next: any = this.list_selected;
    if (next.type == 'video') {
      this.video_playing = next;
      this.update_play(next);
      this.title = next.video.title;
    } else if (next.type == 'pdf') {
      this.title = next.pdf.title;
      this.pdfSrc = "https://e-learning.aimc.or.th/api/Media/" + next.pdf.pdf_data[0].url;
      this.pageVariable = 1;
      if (this.data.detail.is_sliding == '0' && this.list_selected.status != 2) {
        if (isDefined(next.playing.seconds) && next.playing.seconds != 0) {
          this.seconds = next.playing.seconds;
        } else {
          this.seconds = (next.pdf.pdf_data[0].duration - 0) * 60
        }
        console.log('this.seconds', this.seconds);

        this.update_data(0, true);///this.update_data(1, true);

        let time = setInterval(() => {
          if (this.seconds > 0) {
            if (this.document_show) {
              this.seconds--;
              if (this.seconds == 0) {
                this.update_data(1, true);
              } else if (this.seconds % 10 == 0) {
                this.update_data(0, false);
              }
            }

          } else {
            clearInterval(time);
          }
        }, 1000);
      } else {
        this.seconds = 0;
      }
    } else if (next.type == 'info') {
      this.title = next.info.title;
      this.carousel_info = null;
      this.update_data(0, true);
      if (this.data.detail.is_sliding == '0' && this.list_selected.status != 2) {
        if (isDefined(next.playing.seconds) && next.playing.seconds != 0) {
          this.seconds = next.playing.seconds;
        } else {
          let sum = 0;
          next.info.info_data.forEach(element => {
            sum += element.duration;
          });
          this.seconds = sum * 60;
        }
        let time = setInterval(() => {
          if (this.seconds > 0) {
            if (this.document_show) {
              this.seconds--;
              if (this.seconds == 0) {
                this.update_data(1, true);
              } else if (this.seconds % 10 == 0) {
                this.update_data(0, false);
              }
            }
          } else {
            clearInterval(time);
          }
        }, 1000);
      } else {
        this.seconds = 0;
      }
    } else if (next.type == 'test') {
      this.show_test = true;
      if (next.playing.data.length > 0) {
        let last = next.playing.data[next.playing.data.length - 1];
        if (last.result.percen >= last.result.pass_percen && last.success == 1) {
          this.test_result = last.result;
          this.test_success = true;
        }
      }

    } else if (next.type == 'canva') {
      this.title = next.canva.title;
      this.mouseActive();

      this.urlCanva = this.sanitizer.bypassSecurityTrustResourceUrl("https://www.canva.com/design/" + next.canva.url + "/view?embed");
      if (isDefined(next.playing.seconds) && next.playing.seconds != 0) {
        this.seconds = next.playing.seconds;
      } else {
        this.seconds = next.canva.duration * 60;
      }

      let time = setInterval(() => {
        if (this.seconds > 0) {
          if (this.document_show) {
            if (this.activeM < this.timeout) {
              this.seconds--;
              if (this.seconds == 0) {
                this.update_data(1, true);
              } else if (this.seconds % 10 == 0) {
                this.update_data(0, false);
              }
            } else {

              if (this.activeM == this.timeout) {
                // Swal.fire({
                //   title: 'เพื่อเป็นการยืนยันว่าคุณอยู่ในระบบ',
                //   html: 'กรุณาคลิกปุ่มที่หน้าจอ',
                //   didOpen: () => {

                //   },
                //   willClose: () => {

                //   }
                // }).then((result) => {
                //   /* Read more about handling dismissals below */
                //   if (result.dismiss === Swal.DismissReason.timer) {
                //     console.log('I was closed by the timer')
                //   }
                // })
              }
            }

          }

        } else {
          clearInterval(time);
        }
      }, 1000);
    } else if (next.type == 'quiz') {
      this.load_quiz(next.exam);
    }
  }
  mouseMove(e: any) {
    this.time_logout_run = this.time_logout_fix;
    this.activeM = 0
    setTimeout(() => {
      if (this.player) {
        this.player.play()
      }
    }, 300);
  }

  activeM: number = 0;
  timeout: number = 30000;//detault = 30 s
  mouseActive() {
    this.time_logout_run = this.time_logout_fix;
    setInterval(() => {
      this.activeM++
      // console.log(this.activeM);
      if (this.activeM > this.timeout) {
        if (this.player) {
          this.player.pause()
        }
      }
    }, 1000);
  }

  user: any = {
    first_name: "",
    last_name: "",
    phone: "",
    type: "0",
    user_email: "0",
    user_id: "0",
  };
  is_load = false;
  document_show = true;

  ngOnInit(): void {
    console.log(this.isPhotoTaken);
    const scrollPosition = localStorage.getItem('scrollPosition');
    if (scrollPosition) {
      window.scrollTo(0, +scrollPosition);
      localStorage.removeItem('scrollPosition'); // Clear it after use
    }
    this.courseid = this.route.snapshot.paramMap.get("courseid");
    this.topicid = this.route.snapshot.paramMap.get('topicid');
    this.ls_id = this.route.snapshot.paramMap.get('ref_id');
    this.rt_id = this.route.snapshot.paramMap.get('rt_id');
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.user = JSON.parse(localStorage.getItem("userdata"));
    // this.show_logout();

    document.addEventListener('visibilitychange', (event) => {
      // console.log(document.visibilityState);
      if (document.visibilityState == 'hidden') {
        this.document_show = false;
        if (this.list_selected.type == 'video' && this.is_load) {
          this.player.pause();
        }
      } else {
        this.document_show = true;
        this.time_logout_run = this.time_logout_fix;
      }
    });

    setTimeout(() => {
      this.is_load = true;
    }, 2000);
    this.resizeSubscription$ = this.resizeObservable$.subscribe(evt => {
      this.on_resize();
    })
    setTimeout(() => {
      this.on_resize();
    }, 2000);
    setInterval(() => {
      this.time_logout_run -= 10;
      console.log(this.time_logout_run);
      if (this.time_logout_run <= 0) {
        // this.show_logout();

      }
    }, 10000);

    // this.urlSafe = this.sanitizeUrl(this.url);
    this.urlSafe = this.sanitizeUrl(this.url);

    $("#loading").show();
    this.api.getData("get_topic_detail5/" + this.courseid + "/" + this.topicid + "/" + this.rt_id).then((res: any) => {
      $("#loading").hide();
      if (res.flag) {
        this.data = res;
        this.time_logout_fix = res.detail.screen_time * 60;
        this.time_logout_run = this.time_logout_fix;
        if (this.data.detail.is_exp) {
          this.api.log("รอบอบรมของคุณหมดอายุแล้ว");
          setTimeout(() => {
            this.router.navigate(['/topic/' + this.courseid + '/' + this.topicid]);
          }, 3000);

          //  [routerLink]="'/topic/'+courseid+'/'+topicid"
        }

        this.data.list.forEach((element, key) => {
          element.isCollapsed = true;
        });

        // this.load_survey();

        if (this.ls_id == '0') {
          this.play_next();
        } else {
          let flag = true;
          if (this.data.detail.is_sliding == '1' || this.user.type == '1') {
            this.data.list.forEach((group, key) => {
              this.data.list[key].isCollapsed = true;
              group.lessions.forEach(lession => {
                lession.list.forEach(list => {
                  if (list.ls_id == this.ls_id && flag) {
                    this.data.list[key].isCollapsed = false;
                    flag = false;
                    this.list_selected = list;
                    this.play_data();
                  }
                });
              });
            });
            if (flag) {
              this.play_next();
            }
          } else {
            let flag = true;
            this.data.list.forEach((group, key) => {
              this.data.list[key].isCollapsed = true;
              group.lessions.forEach(lession => {
                lession.list.forEach(list => {
                  if (list.ls_id == this.ls_id && (list.status - 0 == 2 || list.status - 0 == 1)) {
                    flag = false;
                    this.data.list[key].isCollapsed = false;
                    this.list_selected = list;
                    this.play_data();
                  }
                });
              });
            });
            if (flag) {
              this.play_next();
            }
          }
        }
      } else {
        this.router.navigate(['/home']);
      }
      var innerWidth = window.innerWidth;
      if (innerWidth <= 990) {
        this.active_tab = 1;
        setTimeout(() => {
          let el: HTMLElement = this.topic_ele.nativeElement;
          el.click();
        }, 500);
      } else {
        this.active_tab = 2;

      }
    });
  }
  logout() {
    localStorage.setItem('auth_token', null);
    this.api.storage_set("userdata", null);
    this.router.navigate(['/login']);
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
  time_logout = 20;
  show_logout() {
    let timerInterval;
    this.time_logout = 20; // Reset the timer value
    Swal.fire({
      title: 'คุณไม่ได้ใช้ระบบนานเกินไป',
      html: 'ออกจากระบบภายใน <b></b> วินาที',
      timer: 20000,
      timerProgressBar: true,
      showCloseButton: true,
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          this.time_logout--;
          const content = Swal.getHtmlContainer();
          if (content) {
            const b = content.querySelector('b');
            if (b) {
              b.textContent = this.time_logout + '';
            }
          }
          if (this.time_logout <= 0) {
            clearInterval(timerInterval);
            Swal.close();
          }
        }, 1000);
      },
      willClose: () => {
        clearInterval(timerInterval);
      }
    }).then((result) => {
      /* Read more about handling dismissals below */
      // if (result.dismiss === Swal.DismissReason.timer) {
      //   console.log('I was closed by the timer')
      //   this.logout();
      // }
    })
  }

  show_serve = true;
  survey = [];
  create_cer() {
    this.api.postData("create_cer", { tg_id: this.data.detail.tg_id, ut_id: this.data.detail.ut_id }).then((res: any) => {
      if (res.success - 0 == 0) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "มีปัญหาในการขอใจ Certificate",
          footer: 'กรุณาติดต่ผู้ดูแลระบบ'
        });
      }

    }).catch((val: any) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "มีปัญหาในการขอใจ Certificate",
        footer: 'กรุณาติดต่ผู้ดูแลระบบ'
      });
    })
  }
  download_cer(file) {
    // this.api.postData("create_cer", { tg_id: this.data.detail.tg_id, ut_id: this.data.detail.ut_id }).then((res: any) => {

    let url = this.api.main_url() + "api/certificate/" + file;
    window.open(url, '_blank').focus();
    // });

  }
  load_survey() {
    console.log(this.data);
    if (this.data.detail.sf_id != '0') {
      this.api.getData("survey/" + this.data.detail.sf_id).then((res: any) => {
        this.survey = res;
        this.list_selected = {
          type: 'survey', ls_id: '', ref_id: '', info: { info_data: [] },
          exam: { title: '', count: 0, time: '', percent: '', ex_repeat: 0 },
          video: { title: '' }, status: 0
        };
      });
    }
  }
  survey_disabled() {
    let out = false;
    this.survey.forEach(element => {
      if (element.ans == 0) {
        out = true;
      }
    });
    return out;

  }
  send_survey() {
    this.api.postData("add_survey2", { data: this.survey, tg_id: this.data.detail.tg_id, ut_id: this.data.detail.ut_id }).then((res) => {
      if (res == '1') {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: 'btn btn-warning',
            cancelButton: 'btn btn-danger'
          },
          buttonsStyling: false
        })

        swalWithBootstrapButtons.fire({
          title: 'ได้รับข้อมูลเรียบร้อย',
          text: "ขอขอบพระคุณสำหรับการร่วมทำแบบประเมิน",
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: '<img src="../../assets/images/Icon feather-home.png" style="width: 15px;margin-top: -5px; margin-right: 5px;"> กลับหน้าหลัก',
          reverseButtons: false
        }).then((result) => {
          if (result.isConfirmed) {
            // console.log("back");
            this.router.navigate(['/topic/' + this.courseid + '/' + this.topicid]);
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {

          }
        })
      }
    })
  }
  toggle(i) {
    this.data.list[i].isCollapsed = !this.data.list[i].isCollapsed;

  }
  selected(detail) {
    if (detail.ref_id == this.list_selected.ref_id && detail.type == this.list_selected.type) {
      return true;
    } else {
      return false;
    }
  }
  goto_list(detail) {
    if (detail.ls_id != this.list_selected.ls_id) {
      console.log(this.user);
      if (this.data.detail.is_sliding == '1' || this.user.type == '1') {
        this.router.navigate(['/learning/' + this.courseid + "/" + this.topicid + "/" + detail.ls_id + "/" + this.rt_id]);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        let flag = true;
        this.data.list.forEach((group) => {
          group.lessions.forEach(lession => {
            lession.list.forEach(list => {
              if (list.ls_id == detail.ls_id && (list.status - 0 == 2 || list.status - 0 == 1)) {
                this.router.navigate(['/learning/' + this.courseid + "/" + this.topicid + "/" + detail.ls_id + "/" + this.rt_id]);
                setTimeout(() => {
                  window.location.reload();
                }, 500);
                flag = false;
              }

            });
          });
        });
        if (flag) {
          this.api.log("ไม่อนุญาติให้ข้ามบทเรียนได้");
        }
      }
    }

  }
  zomechange(e) {
    console.log(e);
  }
  pdf_height = 550;
  window_width = 0;
  on_resize() {
    this.window_width = window.innerWidth;
    let elements = this.elem.nativeElement.querySelectorAll('.ng2-pdf-viewer-container')[0];
    console.log(elements.offsetHeight);
    this.pdf_height = elements.offsetHeight;
    // this.pdfView.nativeElement.offsetHeight;
    //  let heigth =  (<HTMLElement>this.pdfView.nativeElement).getBoundingClientRect().height;
    // console.log(heigth);
    // age.getViewport(

    // this.pdfComponent.g


    // this.pdf_zoom = 1;
    // if (innerWidth <= 1400) {
    //   this.pdf_zoom = 0.8;
    // }
    // if (innerWidth <= 1200) {
    //   this.pdf_zoom = 0.6;
    // }

    // if (innerWidth <= 990) {
    //   this.pdf_zoom = 0.7;
    // }
    // if (innerWidth <= 768) {
    //   this.pdf_zoom = 0.5;
    // }
  }
  ///////////////pdf///////

  page: number = 1;
  totalPages: number;
  prev_pdf() {
    this.pageVariable--;
  }
  next_pdf() {
    this.pageVariable++;
  }
  pageInitialized(e) {
    console.log(e);
  }
  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.on_resize();

  }

  //////////end pdf///////
  ////////////info///////
  paused = false;
  unpauseOnArrow = false;
  pauseOnIndicator = false;
  pauseOnHover = true;
  pauseOnFocus = true;
  togglePaused() {
    if (this.paused) {
      this.carousel.cycle();
    } else {
      this.carousel.pause();
    }
    this.paused = !this.paused;
  }
  onSlide(slideEvent: any) {
    this.pageVariable = slideEvent.current.split("-")[2] - 0 + 1;
    if (this.unpauseOnArrow && slideEvent.paused &&
      (slideEvent.source === NgbSlideEventSource.ARROW_LEFT || slideEvent.source === NgbSlideEventSource.ARROW_RIGHT)) {
      this.togglePaused();
    }
    if (this.pauseOnIndicator && !slideEvent.paused && slideEvent.source === NgbSlideEventSource.INDICATOR) {
      this.togglePaused();
    }
  }


  //////end info///////


  // async run() {
  //   await faceapi.loadFaceRecognitionModel()
  //   await onSelectionChanged(1, $('#selectList1 select').val())
  //   await onSelectionChanged(2, $('#selectList2 select').val())
  //   updateResult()
  // }
  threshold = 0.6
  descriptors = { desc1: null, desc2: null };
  percen_face = 0;
  updateResult() {
    const distance = faceapi.utils.round(
      faceapi.euclideanDistance(this.descriptors.desc1, this.descriptors.desc2)
    )
    let text = Math.ceil((1 - distance) * 100)
    console.log(text);
    this.percen_face = text;
  }
  toDataURL(url, callback) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  async face(url, url2) {
    // await faceapi.nets.ssdMobilenetv1.loadFromUri('https://raw.githubusercontent.com/justadudewhohacks/face-api.js/master/weights');
    // net.load(await faceapi.fetchNetWeights('/models/face_detection_model.weights'))
    // const net = new faceapi.SsdMobilenetv1()
    // await net.loadFromUri('/assets/weights');
    // const net2 = new faceapi.SsdMobilenetv2()
    // await net2.loadFromUri('/assets/weights');
    // await faceapi.nets.ssdMobilenetv1.loadFromUri('/assets/aa');
    // await faceapi.nets.ssdMobilenetv2.loadFromUri('/assets/aa');
    // accordingly for the other models:
    // await faceapi.nets.faceLandmark68Net.loadFromUri('/models')
    // await faceapi.nets.faceRecognitionNet.loadFromUri('/models')
    // ...

    let url1 = url._imageAsDataUrl;
    // url1 = "assets/828_1626359379.png";
    const input1 = await faceapi.fetchImage(url1);
    const input2 = await faceapi.fetchImage(url2);
    // console.log(input1);
    // console.log(input2);
    this.descriptors[`desc1`] = await faceapi.computeFaceDescriptor(input1);
    this.descriptors[`desc2`] = await faceapi.computeFaceDescriptor(input2);
    setTimeout(() => {
      this.updateResult();
    }, 1000);


  }


  async handleImage(webcamImage: WebcamImage) {
    var model = '';
    await faceapi.loadFaceRecognitionModel(model);
    // console.info('received webcam image', webcamImage);
    // console.log("https://e-learning.aimc.or.th/api/take_photo/" + this.data.detail.kyc.profile);
    this.toDataURL(this.api.main_url() + "api/take_photo/" + this.data.detail.kyc.profile, (dataUrl) => {
      // console.log('RESULT:', dataUrl)
      this.face(webcamImage, dataUrl);// this.data.detail.kyc.profile

    })
    this.webcamImage = webcamImage;

  }
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }
  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }
  public triggerSnapshot(): void {
    this.trigger.next();
    this.show_camera = false;
  }

  // @HostListener('window:scroll', ['$event']) onScrollEvent($event) {



  //   const verticalOffset = window.pageYOffset
  //     || document.documentElement.scrollTop
  //     || document.body.scrollTop || 0;
  //   if (verticalOffset > 130) {
  //     this.overflowscallright = true;
  //   } else {
  //     this.overflowscallright = false;
  //   }
  // }

  handleInitError(e) { }
  ngAfterContentInit() {


  }
  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }
  ///////////Quiz///////////////
  quiz_sum_score = 0;
  quiz_finish_click(carousel, update: boolean) {
    if (update) {
      this.update_data(true, true);
    }
    this.quiz_sum_score = 0;
    this.quiz_data.quiz.forEach((element) => {
      let ans = -1;
      element.options.forEach((anser, key) => {
        if (anser.correct) {
          ans = key;
        }
      });
      if (ans == element.ans) {
        this.quiz_sum_score++;
      }
    });

    this.quiz_show_finish = true;
  }

  quiz_select_all() {
    let flag = true;
    this.quiz_data.quiz.forEach(element => {
      if (element.ans == -1) {
        flag = false;
      }
    });

    return flag;

  }
  quiz_active = 1;
  quizonSlide(e) {
    let cur = e.current.split("-")[2] - 0;
    this.quiz_active = cur + 1;
    if (cur % this.quiz_data.quiz.length == this.quiz_data.quiz.length - 1) {
      this.quiz_finish = true;
    } else {
      this.quiz_finish = false;
    }
    if (e.current == 'ngb-slide-0') {
      this.quiz_first = true;
    } else {
      this.quiz_first = false;
    }
  }
  floor(val) {
    let time = Math.floor(val);
    if (time < 10) {
      return time;
    } else {
      return time;
    }
    return 0;
  }
  quize_change(i, j, quiz) {
    if (quiz.correct) {
      this.quiz_data.quiz[i].options[j].c_true = true;
      this.quiz_data.point++;
    } else {
      this.quiz_data.quiz[i].options[j].c_false = true;
      this.quiz_data.quiz[i].options.forEach(element => {
        if (element.correct) {
          element.c_true = true;
        }
      });
    }
    this.quiz_data.quiz[i].disable = true;
  }
  quiz_data = { title: '', quiz: [], point: 0, data: [] };
  test_data = { title: '', test: [], max_time: 0, min_time: 0 };
  next_quiz(carousel) {
    carousel.next();
  }
  back_quiz(carousel) {
    carousel.prev();
  }
  quize_start = true;
  start_quiz_click() {
    this.quize_start = false;
  }
  load_quiz(quiz) {
    $("#loading").show();
    this.api.getData("get_quiz/" + quiz.qg_id).then((res: any) => {
      $("#loading").hide();
      this.quize_start = true;
      this.quiz_data = res;
      this.quiz_data.point = 0;
      this.quiz_data.quiz.forEach(element => {
        element.disable = false;
        element.options.forEach(quiz => {
          quiz.c_true = false;
          quiz.c_false = false;
        });

      });
      this.quiz_finish = false;
      this.quiz_first = true;
      this.quiz_select = false;
      this.quiz_show_finish = false;
    })
  }
  quizModal(data) {
    this.load_quiz(data.data.data.data);
    this.modalService.open(this.quizmodalEle, { size: 'sm', windowClass: "quiz_modal" }).result.then((result) => {
      this.api.postData('add_quiz', { time: data.data.data.time, ref_id: this.list_selected.ref_id, rt_id: this.data.detail.rt_id, }).then((res: any) => {
        this.load(false);
        this.player.removeCuePoint(data.id);

      })
      this.player.play();
    }, (reason) => {
      this.player.play();
    });;
  }
  ////////end quiz/////////
  /////////////test////////////
  show_intro_test = true;
  show_test = false;
  get_count_test(data) {
    let count = 0;
    if (data.playing.type == 'test') {
      data.playing.data.forEach(element => {
        if (element.success == 1) {
          count++;
        }
      });
    }
    return count;

  }
  next_test(carousel) {
    carousel.next();
  }
  back_test(carousel) {
    carousel.prev();
  }
  min_time_test = 0;
  test_active = 1;
  test_success = false;
  load_test(test) {
    $("#loading").show();
    this.api.getData("get_test2/" + test.eg_id + '/' + this.data.detail.rt_id).then((res: any) => {
      $("#loading").hide();
      this.test_success = false;
      this.show_intro_test = false;
      this.show_test = true;
      this.test_data = res;
      this.test_finish = false;
      this.test_first = true;
      this.test_select = false;
      this.test_show_finish = false;
      this.min_time_test = this.test_data.min_time * 60;
      this.seconds = (this.test_data.max_time - 0) * 60;
      setInterval(() => {
        if (this.seconds > 0) {
          this.seconds--;
          console.log(this.seconds);
          if (this.seconds == 0) {
            // this.test_finish_click(null);
            this.change_test(1);
          }
        }
        if (this.min_time_test > 0) {
          this.min_time_test--;
        }
      }, 1000);

      this.update_data(0, true);

    })
  }

  test_sum_score = 0;
  test_finish_click(carousel) {
    this.api.confirm_send_test(() => {
      this.change_test(1);
    }, () => { })

  }
  add0(val: number): string {
    return val < 10 ? '0' + val : val.toString();
  }
  getFormattedTime(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const sec = Math.floor(seconds % 60);
    return `${this.add0(hours)}:${this.add0(minutes)}:${this.add0(sec)}`;
  }
  test_select_all() {
    let flag = true;
    this.test_data.test.forEach(element => {
      if (element.ans == -1) {
        flag = false;
      }
    });
    return flag;

  }
  testonSlide(e) {
    let cur = e.current.split("-")[2] - 0;
    this.test_active = cur + 1;
    if (cur % this.test_data.test.length == this.test_data.test.length - 1) {
      this.test_finish = true;
    } else {
      this.test_finish = false;
    }
    if (e.current == 'ngb-slide-0') {
      this.test_first = true;
    } else {
      this.test_first = false;
    }
  }
  test_result = {
    count_all: 0,
    count_correct: 0,
    count_not_check: 0,
    count_not_correct: 0,
    pass_percen: 0,
    percen: 0,
    time: 0,
    balance: 0,
    date_time: '2021-06-05 12:12:55'
  };
  repeat_test() {
    window.location.reload();

  }
  load_cer() {

  }
  change_test(success) {
    this.api.postData("update_test2", { test: this.test_data, ref_id: this.list_selected.ref_id, rt_id: this.data.detail.rt_id, success: success, success_all: 0, seconds: (this.test_data.max_time - 0) * 60 - this.seconds }).then((res: any) => {
      if (success == 1) {
        // this.load(false);



        this.test_result = res;
        this.test_success = true;
        // if (this.test_result.percen >= this.test_result.pass_percen) {
        //   this.create_cer();
        // }
        this.load(false);
        // this.test_sum_score = 0;
        // this.test_data.test.forEach((element) => {
        //   let ans = -1;
        //   element.options.forEach((anser, key) => {
        //     if (anser.correct) {
        //       ans = key;
        //     }
        //   });
        //   if (ans == element.ans) {
        //     this.test_sum_score++;
        //   }
        // });
        // this.test_show_finish = true;
      }
    })
  }
  get_text_datetime(date) {
    let d1 = date.split(" ");
    let d2 = d1[0].split("-");
    let d3 = d1[1].split(":");
    var monthNamesThai = ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน",
      "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
    return d2[2] + " " + monthNamesThai[d2[1] - 1] + " " + d2[0] + " " + d3[0] + ":" + d3[1] + " น.";

  }
  ///////////////end test/////////
  get_time_text(duration) {
    // if (duration > 59) {
    return Math.floor(duration / 60) + ":" + (duration % 60) + " ";
    // } else {
    //   return duration + " วินาที";
    // }
  }
  get_time_text2(duration) {
    if (duration > 59) {
      let mini = (duration % 60);
      if (mini != 0) {
        return Math.floor(duration / 60) + "." + (duration % 60) + " นาที";
      } else {
        return Math.floor(duration / 60) + " นาที";
      }

    } else {
      return duration + " วินาที";
    }
  }
  time_take_video = "";
  take_photo_data = { id: 0, data: { data: { time: 0 } } };

  isPhotoTaken: any; // ตัวแปรเก็บสถานะการถ่ายรูป

  save_picture(modal: any) {
    this.api.postData('add_take_picture', {
      time: this.take_photo_data.data.data.time,
      img: this.webcamImage,
      ref_id: this.list_selected.ref_id,
      rt_id: this.data.detail.rt_id,
      percen_face: this.percen_face
    }).then((res: any) => {
      console.log('API Response:', res);
      if (res.success == 1) {
        console.log('เข้า if == 1');
        this.player.play();
        this.load(false);
        this.is_take_photo = false;
        this.isPhotoTaken = res.success;
        if (this.take_photo_data.id - 0 != 0) {
          this.player.removeCuePoint(this.take_photo_data.id);
        }
        if (modal && typeof modal.dismiss === 'function') {
          console.log('Calling modal.dismiss()');
          modal.dismiss();
        } else {
          console.error('Modal is undefined or dismiss is not a function');
        }
      } else {
        this.api.log("รูปภาพมีปัญหาหรือมีขนาดเล็กเกินไป กรุณาถ่ายภาพใหม่อีกครั้ง");
      }
    })
      .catch((error) => {
        console.error('Error:', error);
      });

    setTimeout(() => {
      this.show_camera = true;
    }, 1000);
  }

  take_photo(data) {
    if (this.isPhotoTaken == 1) return; // ถ้าถ่ายรูปสำเร็จแล้ว ไม่ต้องแสดง modal อีก
    this.time_take_video = this.get_time_text(data.time);
    this.take_photo_data = data;
    this.webcamImage = null;
    this.modalService.open(this.take_photomodalEle, { ariaLabelledBy: 'modal-basic-title', size: 'sm', windowClass: "take_photo" }).result.then((result) => {
      // handle result
    }, (reason) => {
      console.log(reason);
    });
  }

  title_next = "ดูบทเรียนต่อไป";
  close_finish_videomodalEle = true;
  finish_video() {
    // this.play_next();
    let out = { type: 'none', ls_id: '0' };
    let flag = false;
    let loop = true;
    this.data.list.forEach((group) => {
      group.lessions.forEach(lession => {
        lession.list.forEach(list => {
          if (flag && loop) {
            out = list;
            loop = false;
          }
          if (list.ls_id == this.list_selected.ls_id) {
            flag = true;
          }
        });
      });
    });
    if (out.type == 'test') {
      this.title_next = "ทำแบบฝึกหัดท้ายบท";
    }
    this.api.getData("get_topic/" + this.rt_id + "/" + this.courseid).then((res: any) => {
      // console.log(res);
      // this.topics = res;
    })
    // this.api.getData("get_topic_detail/" + this.courseid + "/" + this.topicid + '/' + this.rt_id).then((res: any) => {
    if (this.close_finish_videomodalEle) {
      this.close_finish_videomodalEle = false;
      this.modalService.open(this.finish_videomodalEle, { ariaLabelledBy: 'modal-basic-title', size: 'sm' }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
        this.close_finish_videomodalEle = true;
      }, (reason) => {
      });
    }
  }
  play_agen(modal) {
    modal.dismiss('dismiss');
    if (this.list_selected.type == "video") {
      this.player.play();
    }
  }
  close_modal(modal) {
    modal.dismiss('dismiss');
  }
  not_take(meta, ref_id) {
    let flag = true;
    this.data.take_picture.forEach((val) => {
      if (val.type == 'take_photo' && val.time == meta.time && ref_id == val.ref_id) {
        flag = false;
      }
    })
    return flag;
  }
  not_quiz(meta) {
    let flag = true;
    this.data.quizs.forEach((val) => {
      if (val.type == meta.type && val.time == meta.time) {
        flag = false;
      }
    })
    return flag;
  }
  max_video_play = 0;
  is_take_photo = false;

  load(next) {
    this.api.getData("get_topic_detail5/" + this.courseid + "/" + this.topicid + '/' + this.rt_id).then((res: any) => {
      if (res.flag) {
        this.data = res;
        this.time_logout_fix = res.detail.screen_time * 60;
        this.time_logout_run = this.time_logout_fix;
        if (this.data.detail.percen == 100 && this.data.detail.cer_url == '') {
          this.create_cer();
        }
        if (next) {
          this.finish_video();
        }
      }
    });
  }

  show_pic(url) {
    this.img_viewer = url;
    this.modalService.open(this.photo_viewer, { size: 'lg' }).result.then((result) => {
    }, (reason) => {
    });;
  }
  find_next() {
    let out = { type: 'none' };
    let flag = true;
    this.data.list.forEach((group, key) => {
      this.data.list[key].isCollapsed = true;
      group.lessions.forEach(lession => {
        lession.list.forEach(list => {
          if ((list.status - 0 == 0 || list.status - 0 == 1) && flag) {
            out = list;
            flag = false;
            this.data.list[key].isCollapsed = false;

          }
        });
      });
    });
    return out;
  }
  next_btn(modal) {
    this.play_next_ls();
    // let next: any = this.find_next();
    // if (next.type != 'none') {
    //   this.router.navigate(['/learning/' + this.courseid + "/" + this.topicid + "/" + next.ls_id]);
    //   setTimeout(() => {
    //     window.location.reload();
    //   }, 500);
    // } else {
    //   this.load_survey();
    // }
    // window.location.reload();
    // modal.dismiss('');
    // this.play_next();
  }

  is_show_next() {
    let out = { type: 'none', ls_id: '0' };
    let flag = false;
    let loop = true;
    this.data.list.forEach((group) => {
      group.lessions.forEach(lession => {
        lession.list.forEach(list => {
          if (flag && loop) {
            out = list;
            loop = false;
          }
          if (list.ls_id == this.list_selected.ls_id) {
            flag = true;
          }
        });
      });
    });
    if (out.type != 'none') {
      return true;
    } else {
      return false;
    }
  }
  play_next_ls() {
    let out = { type: 'none', ls_id: '0' };
    let flag = false;
    let loop = true;
    console.log(this.list_selected);
    this.data.list.forEach((group) => {
      group.lessions.forEach(lession => {
        lession.list.forEach(list => {
          if (flag && loop) {
            out = list;
            loop = false;
          }
          if (list.ls_id == this.list_selected.ls_id) {
            flag = true;
          }
        });


      });
    });
    this.update_data(1, false);
    if (out.type != 'none') {
      this.router.navigate(['/learning/' + this.courseid + "/" + this.topicid + "/" + out.ls_id + "/" + this.rt_id]);
      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } else {
      // this.modalac.close();
      // this.basicModal.hide();
      this.load_survey();
    }
  }
  play_next() {

    let next: any = this.find_next();
    if (next.type != 'none') {
      this.list_selected = next;
      // console.log(this.list_selected);
      this.play_data();
    } else {
      let out = { type: 'none' };
      let flag = true;
      this.data.list.forEach((group, key) => {
        this.data.list[key].isCollapsed = true;
        group.lessions.forEach(lession => {
          lession.list.forEach(list => {
            if (flag) {
              flag = false;
              this.list_selected = list;
              this.data.list[key].isCollapsed = false;
              this.play_data();
            }
          });
        });
      });


    }
  }
  start_test() {

    this.load_test(this.list_selected.exam);
  }


  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }
  show_camera = true;

}
